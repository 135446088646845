.Scratchy {
  width: 75vmin;
  height: 50vmin;
  padding: 30px;
  border-radius: 15px;
  background-color: grey;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}

.Symbols {
  padding: 30px;
  border-radius: 10px;
  background-color: rgba(251, 202, 239, 1);
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px; 
  align-items: space-between;
  justify-items: space-between;
  mask-image: paint(foil);
  --paintlet-history: '';
}

.Symbols > div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: rgba(210, 241, 228, 1);
  font-size: 8.0rem;
}
